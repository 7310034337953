import "../css/tailwind.css";
import "../scss/theme.scss";
import 'animate.css';
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)
window.Alpine = Alpine


document.addEventListener('alpine:init', () => {
    Alpine.data('home', () => ({
        privacy:false,
        legal: false,
        legalCheck: false,
        animateJoinList: false,
        animateEmailFail: false,
        animateCheck: false,
        email:'',
        sentFormOk: false,
        errorOnSentForm: false,
        welcometothelist: false,
        memberExist: false,
        loaderForm: false,
        checkRequired () {
            if (this.legalCheck == false) {
                this.animateCheck = true
                this.animateEmailFail = true
                return false
            } else {
                return this.validarEmail()
            }
        },
        validarEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            // Si el email es válido, animateEmailFail será false, si no, será true
            this.animateEmailFail = !(this.email !== '' && emailRegex.test(this.email));
            return this.email !== '' && emailRegex.test(this.email)
        },
        async enviarFormulario() {
            if (!this.checkRequired()) { // Verifica si el checkbox está marcado y el email es válido
                return; // Si no se cumplen los requisitos, no continúa
            }
            this.loaderForm = true

            const data = {
                email_address: this.email,
                status: "subscribed", // Puedes usar "subscribed", "unsubscribed", "pending", "cleaned"
            };

            try {
                const response = await fetch(`${process.env.API_BASE_URL}`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const result = await response.json();

                if (response.ok || response.status === 400) {
                    // this.animateJoinList = true; // Muestra animación de éxito
                    if (result.status == 400 ) {
                        this.errorOnSentForm = false
                        this.sentFormOk = true
                        this.welcometothelist = false
                        this.loaderForm = false
                        this.memberExist = true
                        // console.log('Existe!', result.status, url, key);
                    } else {
                        this.errorOnSentForm = false
                        this.sentFormOk = true
                        this.welcometothelist = true
                        this.loaderForm = false
                        // console.log('Formulario enviado con éxito');
                    }

                } else {
                    // this.animateEmailFail = true;
                    this.sentFormOk = true
                    this.errorOnSentForm = true
                    this.loaderForm = false
                    // console.error('Error al enviar el formulario', auth);
                }
            } catch (error) {
                console.log('here')
                this.sentFormOk = true
                this.errorOnSentForm = true
                this.loaderForm = false
                console.error('Error en la solicitud:', error);
            }
        }
    }))
});

Alpine.start()
